import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, dispatch } from 'store';
import {
  openProjectCreator,
  setAllConcepts,
  setConceptBeingEdited,
  setProjectMenu,
  setOpenedProjectName,
  setOpenedProjectModules,
  setActiveStep,
  setIsSizeWelcome,
  setIsBrainstormWelcome,
  setIsAiGenerate,
  setAllowRegionsReset
} from 'store/reducers/menu';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery, Button, Typography } from '@mui/material';

// project import
import Search from './Search';
import Profile from './Profile';
import Localization from './Localization';
import Notification from './Notification';
import MobileSection from './MobileSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// types
import { MenuOrientation } from 'types/config';

import Breadcrumbs from 'components/@extended/Breadcrumbs';
import navigation from 'menu-items';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  const menu = useSelector((state) => state.menu);
  const { openedProjectName, activeStep, isResponseLoading } = menu;

  const handleClick = () => {
    dispatch(openProjectCreator(false));
    dispatch(setConceptBeingEdited({ id: '', name: '', logs: [], markets: [] }));
    dispatch(setProjectMenu([]));
    dispatch(setAllConcepts([]));
    dispatch(setOpenedProjectName(''));
    dispatch(setOpenedProjectModules([]));
    dispatch(setActiveStep(0));
    dispatch(setIsSizeWelcome(true));
    dispatch(setIsBrainstormWelcome(false));
    dispatch(setIsAiGenerate(false));
    dispatch(setAllowRegionsReset(false));
  };

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}

      <Box sx={{ display: 'flex', marginRight: 'auto' }}>
        <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
        {openedProjectName && activeStep !== 3 ? (
          <>
            <Typography sx={{ marginLeft: '8px' }} variant="h6">
              /
            </Typography>
            <Typography sx={{ marginLeft: '8px', fontWeight: '600' }} variant="h6">
              {openedProjectName}
            </Typography>
          </>
        ) : null}
      </Box>

      <Button
        component={Link}
        to="/projects"
        variant="outlined"
        sx={{ marginRight: '20px', borderColor: '#262626', color: '#262626' }}
        onClick={handleClick}
        className="ilt-projects-button hidden-disable2"
        disabled={isResponseLoading}
      >
        Projects
      </Button>
      <Search />

      {!downLG && localization}
      {downLG && <Box sx={{ display: 'none', width: '100%', ml: 1 }} />}

      <Notification />
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
